<template>
  <div>
    <b-card
      title="Importar arquivo"
    >
      <b-row>
        <b-col md="3">
          <b-form-file
            v-model="baixaFile"
            class="mt-2"
            accept=".ret, .txt"
            browse-text="Buscar"
            placeholder="Adicione um arquivo"
          />
        </b-col>
        <b-col md="2">
          <label for="">Data da Baixa</label>
          <b-input-group class="mb-1">
            <the-mask
              v-model="dataBaixa"
              autocomplete="off"
              :mask="['##/##/####']"
              :masked="true"
              class="form-control"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="dataBaixa_en"
                show-decade-nav
                button-only
                right
                size="sm"
                locale="en-US"
                aria-controls="example-input"
                @input="changeDataFormat"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col class="d-flex justify-content-start">
          <b-button
            variant="primary"
            @click="importarBaixa"
          >
            Importar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Arquivos Importados">
      <b-row
        align-h="between"
        class="mb-2"
      >
        <b-col lg="1">
          <b-form-select
            v-model="perPage"
            :options="[10, 15, 20]"
            size="sm"
          />
        </b-col>
        <b-col lg="8">
          <b-form-input
            v-model="filter"
            placeholder="Pesquisar"
            type="search"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        responsive
        :fields="fields"
        :items="baixas"
        :filter="filter"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template
          #cell(acoes)="data"
        >
          <b-row align-h="center">
            <b-col cols="auto">
              <feather-icon
                variant="danger"
                icon="EyeIcon"
                class="cursor-pointer"
                @click="viewSummary(data.item)"
              />

            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-modal
        ref="summary"
        title="Resumo boletos"
        ok-only
        no-close-on-backdrop
      >
        <app-timeline>
          <app-timeline-item>
            <div>
              <h6>
                Credor
              </h6>

              <b-list-group
                flush
                class="mt-1"
              >
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center bg-transparent"
                >
                  <span><strong>Quantidade: </strong></span>
                </b-list-group-item>
                <b-list-group-item
                  href="#"
                  class="d-flex justify-content-between align-items-center bg-transparent"
                >
                  <span><strong>Valor: </strong></span>
                </b-list-group-item>

                <br>
              </b-list-group>

            </div>
          </app-timeline-item>
        </app-timeline>
      </b-modal>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
      />
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BFormFile,
  BButton,
  BCol,
  BRow,
  BFormInput,
  BFormSelect,
  BTable,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BListGroup,
  BListGroupItem,

} from 'bootstrap-vue'

import { TheMask } from 'vue-the-mask'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import axios from '@/../axios-auth'

export default {
  components: {
    BCard,
    BFormFile,
    BButton,
    BCol,
    BRow,
    BFormInput,
    BFormSelect,
    BTable,
    BPagination,
    TheMask,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      filter: '',
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      baixaFile: null,
      fields: [
        { key: 'id', label: 'id', class: 'text-center' },
        { key: 'banco', label: 'banco', class: 'text-center' },
        { key: 'convenio', label: 'convenio', class: 'text-center' },
        { key: 'cnab', label: 'cnab', class: 'text-center' },
        { key: 'arquivo', label: 'arquivo', class: 'text-center' },
        { key: 'data_hora', label: 'data_hora', class: 'text-center' },
        { key: 'qt_baixada', label: 'qt_baixada', class: 'text-center' },
        { key: 'total', label: 'total', class: 'text-center' },
        { key: 'acoes' },
      ],
      baixas: [],
      dataBaixa: '',
      dataBaixa_en: null,
    }
  },
  watch: {
    dataBaixa(n) {
      if (n.length === 10) {
        const splited = n.split('/')
        const newDate = `${splited[2]}-${splited[1]}-${splited[0]}`
        if (this.dataBaixa_en !== newDate) {
          this.dataBaixa_en = newDate
        }
      }
    },
  },
  created() {
    this.listaBaixa()
  },
  methods: {
    async listaBaixa() {
      this.baixas = []
      await axios.get('api/v1/baixar_boleto/index', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.baixas.unshift({
            id: dt.id,
            banco: dt.banco,
            convenio: dt.convenio,
            cnab: dt.cnab,
            arquivo: dt.descricao,
            data_hora: this.dataHora(dt.created_at),
            qt_baixada: dt.quantidade_baixada,
            total: this.valorBr(parseFloat(dt.valor_total_baixado), true),
          })
        })
        this.totalRows = this.baixas.length
      })
    },

    changeDataFormat(item) {
      const splited = item.split('-')
      const newDate = `${splited[2]}/${splited[1]}/${splited[0]}`
      if (!this.dataBaixa !== newDate) {
        this.dataBaixa = newDate
      }
    },

    async importarBaixa() {
      const formData = new FormData()
      formData.append('remessa', this.baixaFile)
      formData.append('data_baixa', this.dataBaixa)
      await axios.post('api/v1/baixar_boleto/boleto/', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          title: 'SUCESSO!',
          text: 'Arquivo importado com sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
          buttonStyling: false,
        })
      }).catch(() => {
        this.$swal({
          title: 'ERROR!',
          text: 'ERRO AO IMPORTAR ARQUIVO!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
      this.listaBaixa()
    },
    viewSummary() {
      this.$refs.summary.show()
    },
  },
}
</script>
